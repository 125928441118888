import SlideContent from './SlideContent.js';
import data from './data.js';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

function Projects() {
  console.log(data);
  return (
    <CarouselProvider
      visibleSlides={1}
      totalSlides={data.reduce((acc, current)=> acc+current.code, 0)}
      step={1}
      isIntrinsicHeight
      dragEnabled={false}
      touchEnabled={false}
    >
      <Slider className='slider'>
        {data.map((el, id) => {
          if(el.code)
          {
            return (
                <Slide key={id} index={id}>
                  <SlideContent
                    name={el.name}
                    authors={el.authors}
                    link={el.link}
                    content={el.content}
                    images={el.images}
                    date={el.date}
                  />
                </Slide>
            );
          }
        })}
      </Slider>
      <div className='buttons'>
        <DotGroup className='dotGroup' showAsSelectedForCurrentSlideOnly />
        <ButtonBack className='buttonBack arrow arrow-left'></ButtonBack>
        <ButtonNext className='buttonNext arrow arrow-right'></ButtonNext>
      </div>
    </CarouselProvider>
  );
}

export default Projects;
